.site-header .account-details{line-height:30px}
.site-header .account-details>.account-details-name{line-height:45px;font-size:1.125em}
.site-header .account-details>.account-details-usernamelogout{line-height:1em;font-size:1em}
@include media-breakpoint-down(sm) {
 .site-header .account-details>.account-details-name{line-height:35px;font-size:.9em;font-weight:500 !important}
 .site-header .account-details>.account-details-usernamelogout{font-size:.7em}
}
@include media-breakpoint-down(md) {
 .site-header .account-details>.account-details-name{font-size:1em}
 .site-header .account-details>.account-details-usernamelogout{font-size:.75em}
}

.site-header .sound-icon{line-height:65px}
.site-header .sound-icon>i{font-size:35px;color:#999;padding:0 5px}
@include media-breakpoint-down(sm) {.site-header .sound-icon{line-height:50px} .site-header .sound-icon>i{font-size:20px}}
@include media-breakpoint-down(md) {.site-header .sound-icon>i{font-size:25px;padding:0 2px}}

.header-nav-bar .fas, .header-nav-bar .far {
	font-size:1.75rem;
}.header-nav-bar .coin-text {
	font-size:0.80rem;
}

.header-nav-bar .btn-primary {
	padding: .48rem .75rem;
}

.dummy-rule211128{margin:auto}



@media (min-width:993px) and (max-width:1024px) {
	.public .navbar-expand-lg .navbar-nav {
	    margin-left: 20px !important;
	}
}