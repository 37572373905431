

/* general setup */


/* body {background-color:#efefef;} */
a:hover {text-decoration: none;}
h2 {line-height: 1.5em;}
.borderless { border: 0 none; }
.circle-sm {width: 16px;height: 16px;display: inline-block;vertical-align: middle;}




/* font size test */
.fs-15 {font-size: 1.5em;}
.fs-1 {font-size: 1em;}	
.fs-125 {font-size: 1.25em;line-height: 1.25em;}	
.fs-12 {font-size: 1.2em;line-height: 1.2em;}		
.fs-1125 {font-size: 1.125em;line-height: 1.125em;}		
.fs-05 {font-size: 0.5em;}	
.fs-md-1 {font-size: 1em !important;}
.fs-md-15 {font-size: 1.5em;}
.fs-md-075 {font-size: 0.75em !important;}


@media (min-width:768px) {}
@media (min-width:992px) {}


header.site-header-container>.site-header {
	z-index:1000;	
}

/* avatar */
.site-header .student-avatar{height:50px;width:50px;border-radius:30px;overflow:hidden;background-color:#3abbf2;position:relative;overflow:hidden}
.site-header .student-avatar>img{margin-top:-20px;width:100%;vertical-align:middle}
.site-header .student-avatar>.student-avatar-eyes{position:absolute;top:0;left:0}
.site-header .account-details>.account-details-name {line-height: 35px;font-size: 1.2em;}	
.site-header .account-details>.account-details-usernamelogout {line-height: 1em;font-size: 0.785em;}
.site-header .icon-navigation img {width: 35px;}	
.site-header .student-avatar-eyes {margin-top: -13px !important;margin-left: 4px !important;;width: 42px !important;;}

@media (min-width:768px) {
	.site-header .student-avatar{height:50px;width:50px;}
	.site-header .account-details>.account-details-name {line-height: 45px;font-size: 1.025em;}
	.site-header .account-details>.account-details-usernamelogout {line-height: 1em;font-size: 1em;}
	.site-header .icon-navigation img {width: 40px;}
	.site-header .student-avatar-eyes {margin-top: -14px !important;margin-left: -2px;}
}

.site-menu-large {
	.modal-dialog {
	    max-width: 1200px;
	    margin: 1.75rem auto;
	}
}





/* not filtering through for some reason  */
.fa-dollar:before, .fa-usd:before {content: "\f155";}

/* coins */
.coins {background: #1682bf;width: 90px;border-radius: 7px;padding: 4px;height:24px;box-shadow: 0 2px 3px rgba(24,116,183,0.55), inset -1px -1px 4px rgba(0,55,113,1);background: linear-gradient(#41bae7, #1682bf);margin-right:10px;}
@media (min-width:768px) {}
@media (min-width:992px) {.coins {width: 140px;padding: 6px;height:40px;margin-right:30px;}}



.coins-collect {width:57px; border-radius:5px;  text-shadow:0 2px 0 #1578bb; padding:2px 7px; white-space:nowrap; overflow:hidden; font-size:9px;  box-shadow:inset 0 5px 4px rgba(0,0,0,0.26), inset 0 0 6px rgba(0,0,0,0.23); min-height:16px; height: 16px; }
@media (min-width:768px) {}
@media (min-width:992px) {.coins-collect {width:85px; border-radius:5px;  padding:2px 7px; font-size:15px;   min-height:25px;height: auto;  }}




/* dollar coin */
.dollar-coin  {top:-10px; left:-12px; border:solid 2px #FFF; box-shadow:0 1px 0 0 rgba(0,0,0,0.15); transition: all 0.4s; }
.dollar-coin:hover { transform: scale(1.1); }
.dollar-coin span {border: solid 2px #ffe556; text-shadow: 1px 1px 0 rgba(137,64,0,0.7);box-shadow: 0px 1px 1px 1px #be6803, inset 0 1px 1px 0 #d0810c, inset 0 4px 8px 0 rgba(210,131,12,0.50); width:33px; height:33px;  font-size:30px; line-height:16px;}
.dollar-coin span i {font-style: italic;margin-left: -1px;font-size:19px;}
.dollar-coin span {    width: 18px;height: 18px;font-size: 18px;line-height: 11px;}	
.dollar-coin span i {font-size: auto;font-size:13px;}	
	
@media (min-width:992px) {

}


/* check circle / coin */
.check-circle{font-size:30px;   border:solid 1px #FFF; line-height:35px; text-shadow:0 1px 1px rgba(0,0,0,0.25); box-shadow:0 1px 0 1px rgba(0,0,0,0.25); top:-10px; left:-10px; transition: all 0.4s; }
.check-circle span{width:33px; height:30px;    line-height: 20px;}
.check-circle:hover { transform: scale(1.1); }
.check-circle span i {font-size: 20px;padding-top: 7px;}
	.check-circle span {    width: 18px;height: 18px;font-size: 18px;line-height: 11px;}	
	.check-circle span i {    font-size: 12px;line-height: 8px;}
@media (min-width:992px) {
	

}









@media only screen and (max-width: 992px) {
	/* global */
	.h2, h2 {font-size: 1.5rem;}
	.fs-15 {font-size: 1.5em;}
	.fs-1 {font-size: 1em;}
	.fs-075 {font-size: 0.75em !important;}
	
	.site-header .logo-sl {
	    max-width: 60px !important;
	}	
}

.student-logged{
	

	header.site-header-container {
	    min-height: 0px;margin-bottom:5px;
	}
	
	header.site-header-container>.site-header {
	
	    min-height: 0px;
	
	}
	
	a.navbar-brand img {
	    height: 36px !important;
	    width: 150px !important;
	    margin-top: 3px;
	}
	.site-header .logo {
	    line-height: 40px;
	    padding: 0;
	    margin: 0 20px 0 0;
	}
	.site-header .icon-navigation>.list-inline-item {
	    line-height: 40px !important;
	    margin-right: .25rem;
	}
	.site-header .student-avatar {
	    height: 35px;
	    width: 35px;
	     
	}
	.header-nav-bar .far, .header-nav-bar .fas {
	    font-size: 1.5rem;
	}
	.student-avatar img {
		margin-top: -10px !important;
	}
}