.site-menu .modal-header{height:70px; background-color: #d8e0ea;}
.site-menu .modal-header h4{background-color:#fdb913;color:#fff;text-shadow:0 2px 2px rgba(0,0,0,.25);padding:3px 30px;margin-left:-16px}

.site-menu .modal-header .close{opacity:.9;margin-top:-50px;margin-right:-50px}
.site-menu .modal-header .close>i{display:block;line-height:35px;font-size:27.5px;background-color:#f00;color:#fff;border:4px solid #fff;border-radius:50%;width:42px}
.site-menu .modal-header .close:hover{opacity:1}

.site-menu .modal-body h3{border-radius:0px;margin-left:15px; padding-bottom:10px;; border-bottom:1px solid #ccc;}
.site-menu .modal-body h3>img{height:26px;margin-left:15px}
.site-menu .modal-body .list-group-flush{margin-bottom:10px}
.site-menu .modal-body .list-group-flush .list-group-item:first-child{border-top:0}

.site-menu .modal-body .list-group-flush .list-group-item{border:none;}

.site-menu .modal-body {
	padding-left:3rem; padding-right:3rem;padding-bottom:3rem;
}
.profile-menu {
	list-style: none;padding-left: 0px;padding-top:1rem;

}
.profile-menu li {
		font-size: 1.125rem;
		//font-weight: 500;
		padding-bottom:0.5rem; padding-top:0.5rem;font-weight: 500;
}
.profile-details {
	font-size:0.875rem;
}

#studentMenuModal li{
			font-size: 1.125rem;padding-bottom:0.5rem; padding-top:0.5rem;font-weight: 500;
}
.refund-badge {
	position: absolute;
	max-width: 6.5rem;
	top: -3.8rem;
	right: -2.5rem;
	z-index: 1;
}

#teacherMenuModal .list-group-item {
	padding: .35rem 1.25rem;
}

#teacherMenuModal .modal-body h3 {
	font-size: 20px;
}
.site-menu .modal-header .close>i {
    background-color: #5390d4;		
}

.menu-logo img  {
    width: 150px;
    margin-top: 5px;
}
    
    
@media only screen and (max-width: 576px)  {
	#teacherMenuModal .modal-header .close {
	    opacity: .9;
	    margin-top: -40px;
	    margin-right: -40px;
	}
	#teacherMenuModal {
		.list-group-item {
		    padding: .25rem 1.25rem;padding-left: 0px;
		}
	}
	#teacherMenuModal .modal-body h3 {
	    font-size: 14px;
	    padding-bottom: 5px;
	    margin-left: 0px;
	}	
	#teacherMenuModal .modal-body h3>img {
	    height: 18px;
	    margin-left: 15px;
	}	
}