/**
* Push footer to the bottom of the browser window
*/
body{display:flex;min-height:100vh;flex-direction:column}
body>main{flex:1 1 auto;}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  body>main{flex:0 1 auto;}
}
header.site-header-container{min-height:80px}
header.site-header-container>.site-header{position:fixed;top:0px;min-height:80px;z-index:10;background-color:#fff;border-bottom:1px solid #c9d3d3;width:100%}

.site-header .logo{line-height:65px;padding:0;margin:0 20px 0 0}
.site-header .logo>a{font-family:TimesNewRoman,Times New Roman,Times,serif;color:#000;font-weight:500}
.site-header .logo>a:hover{text-decoration:none}
.site-header .logo>a>em{font-style:italics;color:#25aae1}

.navbar-light .navbar-nav .nav-link:hover {color: rgba(0,0,0,1)!important;}

.btn-join {font-weight:500;}
.btn-login {font-weight:500;}


@media (max-width:767.98px) {
 header.site-header-container{min-height:60px}
 header.site-header-container>.site-header{min-height:60px}
 .site-header .logo{line-height:55px;font-size:1.6rem}
 .site-header nav.navbar {padding: 0px;}

}

@media (max-width:991.98px) {

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .navbar-nav.my-auto {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  }


  @media (orientation: portrait) {
    .account-btns {
      margin-bottom: 20vh;
    }
  }
  .navbar-nav {text-align: center;}
  .site-header .navbar-collapse {
    height: calc(100vh - 40px) !important; /* display height - logo section */
    padding-bottom: 1rem;
    position: relative;
  }
  .site-header .nav-item .nav-link {font-size: 1.4rem;padding-top: 0.2rem;padding-bottom: 0.2rem;}
  .site-header .btn-join {width: 100%; -moz-transition: none;-webkit-transition: none;-o-transition: color 0 ease-in;transition: none;}
  .btn-login {width: 100%; -moz-transition: none;-webkit-transition: none;-o-transition: color 0 ease-in;transition: none;  }
  .navbar-light .navbar-toggler {border:none;outline:none; }

}
@media (max-width:991.98px) { .site-header .logo{margin-right:5px} .site-header .logo{font-size:1.8rem} }

.site-header .icon-navigation{margin-bottom:0}
.site-header .icon-navigation>.list-inline-item{line-height:65px;margin-right:.25rem}
.site-header .icon-navigation img{width:55px}
@media (max-width:767.98px) {
 .site-header .icon-navigation img{width:40px}
 .site-header .icon-navigation>.list-inline-item{line-height:55px}
}
@media (max-width:991.98px) { .site-header .icon-navigation img{width:45px} }

